import { HashLink } from 'react-router-hash-link';

import logo from "../../assets/logo_vs.svg";
import star1 from "../../assets/star1.svg";
import star2 from "../../assets/star2.svg";
import star3 from "../../assets/star3.svg";
import logotg from "../../assets/logo_tg.svg";
import logovb from "../../assets/logo_vb.svg";
import {connect} from "react-redux";

const Header = props => {

    const counter = (props.counters && props.counters.TotalDreams) ? props.counters.TotalDreams : 0

    const liItems = [];
    Array.from(counter.toString()).map((item, index) =>
        liItems.push(<li key={index}>{item}</li>))

    const counterComponent = liItems.length > 0 ?
        <ul>{liItems}</ul> : null;

    return (


        <div className='header-wrapper'>
            <div className="header-container">
                <div>
                    <img src={logo} alt="logo" />
                </div>
                <div className="mt-30 d-flex-wrap-sb">
                    <div>
                        <h1 className="mb-10 mr-30">Беларусь<br/>мечтает</h1>
                        <h3 className="mb-20 mr-30">Сбор идей для Новой Беларуси</h3>
                    </div>
                    <div className="counter">
                        {counterComponent}
                        <h2>мечт собрано</h2>
                    </div>
                </div>
                <div>
                    <div className="separator" />
                    <div className="mt-30 mb-30 d-flex-wrap-sb">

                        <div className="header-intro">
                            <div className="w-300 mr-20 mb-30">
                                <div className="d-flex">
                                    <div className="mr-10">
                                        <img src={star1} alt="star 1"/>
                                    </div>
                                    <p className="h-100">Запишите свою новогоднюю мечту на видео или текстом

                                    </p>
                                </div>
                                <div className="pl-80">
                                    <HashLink to="/#about"><button>Больше о проекте</button></HashLink>
                                </div>
                            </div>
                        </div>

                        <div className="header-intro">
                            <div className="w-300 mr-20 mb-30">
                                <div className="d-flex">
                                    <div className="mr-10">
                                        <img src={star2} alt="star 1"/>
                                    </div>
                                    <p className="h-100">Загрузите видео или текст в чат-бот «Голоса» в Viber или Telegram</p>
                                </div>
                                <div className="pl-80">
                                    <a href="https://tttttt.me/golosby_bot" target="_blank"  rel="noreferrer"><img src={logotg} alt="logo tg" /></a>
                                    <a href="viber://pa?chatURI=golos-by" target="_blank"  rel="noreferrer"><img className="ml-10" src={logovb} alt="logo tg" /></a>
                                </div>
                            </div>
                        </div>

                        <div className="header-intro">
                            <div className="w-300">
                                <div className="d-flex">
                                    <div className="mr-10">
                                        <img src={star3} alt="star 1"/>
                                    </div>
                                    <p className="h-100">Пока ваше сообщение проходит модерацию – смотрите и читайте мечты других белорусов</p>
                                </div>
                                <div className="pl-80">
                                    <HashLink to="/#map"><button>Смотреть видео</button></HashLink>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = ({ data: { counters }}) => {
    return { counters };
};

export default connect(mapStateToProps)(Header);
