import ReactPlayer from 'react-player'

const BackgroundVideo = props => {

    return (
        <div className='player-wrapper'>
                <ReactPlayer width="1920"
                             height="1080"
                             playing={true}
                             loop={true}
                             controls={false}
                             muted={true}
                             url='https://d28xp61908rgs.cloudfront.net/599071325-hny.mp4' />
        </div>
    )
}

export default BackgroundVideo;
