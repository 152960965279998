
const SERVER_URL = process.env.REACT_APP_DREAMING_API_URL ?
    process.env.REACT_APP_DREAMING_API_URL : "";

const COUNTERS_URL = process.env.REACT_APP_STATS_BUCKET_URL ?
    process.env.REACT_APP_STATS_BUCKET_URL : "";

export default class APIService {

  _selected = [
    {
      title: "Светлана Тихановская",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/ST.png",
      url: "https://d28xp61908rgs.cloudfront.net/st.mp4"
    },
    {
      title: "Павел Латушко",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/PL.png",
      url: "https://d28xp61908rgs.cloudfront.net/6d030f5e-23ed-4645-888f-77be99fa411c.mp4"
    },
    {
      title: "Александра Герасименя",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/AG.png",
      url: "https://d28xp61908rgs.cloudfront.net/1ffd2879-2d61-4f62-aa61-c619bdb7d746.mp4"
    },
    {
      title: "Вадим Прокопьев",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/VP.png",
      url: "https://d28xp61908rgs.cloudfront.net/66c59d8b-03a5-4ac8-bade-af1a09f2d2e0.mp4"
    },
    {
      title: "Екатерина Снытина",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/ES.png",
      url: "https://d28xp61908rgs.cloudfront.net/es.mp4"
    },
    {
      title: "Максим Богрецов",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/MB.png",
      url: "https://d28xp61908rgs.cloudfront.net/cedb02a3-0987-4469-9078-8677324c473e.mp4"
    },
    {
      title: "Nizkiz",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/ng.png",
      url: "https://d28xp61908rgs.cloudfront.net/nz.mp4"
    },
  ]

  _videos = {
    videos: [
      {
        id: 1,
        type: "video",
        preview: "https://d22aieqyccwq2.cloudfront.net/6499-5179-0987/347726657-preview2.png",
        url: "https://d22aieqyccwq2.cloudfront.net/6499-5179-0987/707543082-trim.4745232E-6E44-4821-89D7-FB8A1FDA3C8E.MOV"
      },
      {
        id: 3,
        type: "video",
        preview: "https://d22aieqyccwq2.cloudfront.net/6499-5179-0987/347726657-preview2.png",
        url: "https://d22aieqyccwq2.cloudfront.net/6499-5179-0987/427878648-trim.DDA89B26-4B34-4E41-BCBD-154BB80DD976.MOV"
      },
      {
        id: 6,
        type: "video",
        preview: "https://d22aieqyccwq2.cloudfront.net/6499-5179-0987/347726657-preview2.png",
        url: "https://d22aieqyccwq2.cloudfront.net/6499-5179-0987/495600591-trim.F20A8185-9292-4100-9511-DB4048091875.MOV"
      },
      {
        id:7,
        type: "video",
        preview: "https://d22aieqyccwq2.cloudfront.net/6499-5179-0987/839128876-preview1.png",
        url:"https://d22aieqyccwq2.cloudfront.net/6499-5179-0987/608958703-testvideo.mp4"
      }
    ],
    texts: [
      {
        id: 2,
        type: "text",
        text: "В новом году хотелочь бы, чтобы в Беларуси начал работать раздельный сбор мусора и чтобы каждый гражданин ответсвенно относился к экологии"
      },
      {
        id: 4,
        type: "text",
        text: "Я бы очень хотел, чтобы общество наконец стало многопартийным и гражданским",
      },
      {
        id: 2,
        type: "text",
        text: "В новом году хотелочь бы, чтобы в Беларуси начал работать раздельный сбор мусора и чтобы каждый гражданин ответсвенно относился к экологии"
      },
      {
        id: 4,
        type: "text",
        text: "Я бы очень хотел, чтобы общество наконец стало многопартийным и гражданским",
      },
    ]
  }

  _stats = {
    TotalDreams: 8,
    PublishedDreams: 1
  }


  getAsyncFetch = (urladdon, requestOptions={}, serverToLoad=SERVER_URL) => {

    console.log("requesting data from: " + (serverToLoad + urladdon));

    return fetch(serverToLoad + urladdon, requestOptions)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            return res.json()
          } else {
            Promise.reject(res.statusText);
          }
        })
        .then(res => {
          if(res === undefined) {
            Promise.reject("Произошла ошибка загрузки данных. Мы обязательно исправим ее в ближайшее время");
          }
          return Promise.resolve(res)
        })
        .catch(error => {
          Promise.reject(error);
        })
  }

  getSelected = async () => {
    return this._selected;
  };

  getVideosByParam = async (bounds, offset, limit, type) => {
    if (SERVER_URL === "") return this._videos;

    const coordinates = (bounds) ? `sw=${bounds.sw}&ne=${bounds.ne}`: "sw=-90,-180&ne=90,180";
    const page = `&limit=${limit}&offset=${offset}`;
    const typeStr = `&type=${type}`

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    return this.getAsyncFetch("/list?" + coordinates + page + typeStr, requestOptions);
  };

  getTextsPreview = async (limit, type) => {
    if (SERVER_URL === "") return this._videos;

    const params = `?limit=${limit}&type=${type}`;

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    return this.getAsyncFetch(`/preview${params}`, requestOptions);
  };

  getCounters = async () => {
    if (COUNTERS_URL === "") return this._stats;

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    return this.getAsyncFetch("", requestOptions, COUNTERS_URL);
  };

}
