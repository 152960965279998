
import FormMap from "./form-map";
import MainPreview from "../main-preview";
import { connect } from "react-redux";
import { updateMapVisibleArea } from "../../actions";

const Map = props => {

    const updateFromMap = ({ bounds, zoom, center }) => {
        props.updateMapVisibleArea({ bounds, zoom, center });
    }

    const videos = props.videos
    const texts = props.texts

    return (
        <div id="map">
            <div className="map-intro">
                <h2 className="mr-20">Мечты на карте</h2>
                <p className="fs-12 mt-15">Чтобы менять подборку желаний, перемещайтесь по карте или меняйте ее масштаб</p>
            </div>
            <div className="map-container">
                <div className="map">
                    <FormMap
                      videos={videos}
                      texts={texts}
                      initialZoom={props.zoom}
                      callback={updateFromMap}
                    />
                </div>
                <div className="d-flex-center map-video">
                    {(videos && videos.length > 0) &&
                    <MainPreview content={videos} callback={() => props.callback("video")} />
                    }
                    {(texts && texts.length > 0) &&
                    <MainPreview content={texts} callback={() => props.callback("text")} />
                    }
                </div>

            </div>
        </div>
    );

}


const mapStateToProps = ({ data: { visibleTextPreview, visibleVideos, zoom }}) => {
    return { videos: visibleVideos, texts: visibleTextPreview, zoom };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateMapVisibleArea: (payload) => dispatch(updateMapVisibleArea(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

