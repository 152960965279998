import {connect} from "react-redux";

const SelectedDreams = props => {

    const playSingleVideo = (index) => {
        props.callback(index)
    }

    return (
        <div className='selected-container'>
            <h2 className="mb-30 wp-100">Мечты замечательных людей</h2>
            {
                props.selected.map((item, index) => {
                    return (
                        <div key={index} className="thumbnail" onClick={() => playSingleVideo(index)}>
                            <img src={item.preview} alt="thumbnail"/>
                            <div className="labelBg">{item.title}</div>
                        </div>
                        )
                    })
            }
        </div>
    )
}

const mapStateToProps = ({ data: { selected }}) => {
    return { selected };
};

export default connect(mapStateToProps)(SelectedDreams);
