import play from "../../assets/ico_play.svg";
import {useState} from "react";

const MainPreview = props => {

    const [isImageErrored, setImageError] = useState(false)

    const contentObject = props.content[0];
    const textspreview = props.content.length > 5 ? props.content.slice(0, 5): props.content

    return (
        <div className="hp-100 wp-100">
            {(contentObject.type === "video") &&
            <div className="d-flex-center hp-100 wp-100">
                <div className="hp-100 wp-100">

                    {!isImageErrored &&
                        <img className="video-preview" src={contentObject.preview} onError={() => setImageError(true)} alt="preview" />
                    }

                </div>
                <div className="video-play" onClick={() => props.callback()}>
                    <div className="play-button">
                        <img className="mr-10" src={play} alt="play button" />
                        <span>Смотреть</span>
                    </div>
                </div>
            </div>
            }
            {(contentObject.type === "text") &&
            <div className="d-flex-center hp-100 wp-100">
                <div className="video-text-preview" style={{opacity: .5}}>
                    {
                        textspreview.map((item) => <p key={item.id} className="mt-30 mb-30">{item.text}</p>)
                    }
                </div>
                <div className="video-play" onClick={() => props.callback()}>

                    <div className="play-button">
                        <img className="mr-15" src={play} alt="play button" />
                        <span>Читать</span>
                    </div>

                </div>
            </div>
            }
        </div>
    );

}


export default MainPreview;
