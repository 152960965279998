import {useState, useEffect} from 'react'
import ReactPlayer from 'react-player'

import close from "../../assets/ico_closeny.svg"
import soundOff from "../../assets/ico_sound_off.svg"
import left from "../../assets/ico_left.svg"
import right from "../../assets/ico_right.svg"
import pause from "../../assets/ico_pause.svg"
import {connect} from "react-redux";

let timer = 0;
let seconds = 0;
let isLoading = true;
let urlsToPlay = []

const AUDIO_URL = "https://d22aieqyccwq2.cloudfront.net/6563-8340-1681/230966610-christmas.mp3";

const PopupPlayer = props => {

    useEffect(() => {

        const soundInStorage = localStorage.getItem("sound")
        if (!soundInStorage) {
            console.log('Sound in local storage is empty');
        } else {
            updateSound(soundInStorage === "true")
        }

        const pauseInStorage = localStorage.getItem("pause")
        if (!pauseInStorage) {
            console.log('Pause in local storage is empty');
        } else {
            updatePause(pauseInStorage === "true")
        }

        return () => {
            clearTimeout(timer)
        }
    }, [])

    const [indexToPlay, updateIndexToPlay] = useState(props.index)
    const [isSound, updateSound] = useState(true)
    const [paused, updatePause] = useState(false)

    const updateIndex = () => {
        clearTimeout(timer)
        updateIndexToPlay(
          indexToPlay + 1 >= urlsToPlay.length
                    ? 0
                    : indexToPlay + 1
        )
    }

    const updateIndexBack = () => {
        clearTimeout(timer)
        updateIndexToPlay(
          indexToPlay > 0
                ? indexToPlay - 1
                : urlsToPlay.length - 1
        )
    }

    const timerHandler = () => {
        updateIndex()
    }

    const reduceAudioVolume = () => {

        let audio = document.getElementById("audioplayer");
        if (audio) {
            audio.volume = .1;
        }
    }

    const setPause = (isPause) => {
        if (isPause) {
            console.log("paused")
            clearTimeout(timer)
        } else {
            timer = setTimeout(timerHandler, seconds);
        }
        localStorage.setItem("pause", isPause)
        updatePause(isPause)
    }

    const closePopup = () => {
        isLoading = true;
        urlsToPlay = []
        props.callback();
    }

    const triggerSound = () => {
        localStorage.setItem("sound", !isSound)
        updateSound(!isSound)
    }

    const navigateToDreams = () => {
        closePopup()
        props.history.push('/dreams');
    }

    if (isLoading) {
        if (props.type === "video" && !props.vloading) {
            urlsToPlay = [...urlsToPlay, ...props.videos]
            isLoading = false
        }
        if (props.type === "text" && !props.tloading) {
            urlsToPlay = [...urlsToPlay, ...props.texts]
            isLoading = false
        }
    }

    if (props.type === "selected" ) {
        urlsToPlay = props.selected;
    }

    const currentIndex = indexToPlay >= urlsToPlay.length ? indexToPlay - 1 : indexToPlay

    const objToPlay = urlsToPlay[currentIndex];

    if (props.type === "text" && !paused) {
        seconds = objToPlay.text.length * 50;
        if (seconds < 3000) seconds = 3000;
        if (seconds > 20000) seconds = 20000;
        timer = setTimeout(timerHandler, seconds);
    }

    console.log(urlsToPlay)
    console.log(indexToPlay)
    console.log(objToPlay)

    return (
        <div className='popup'>
            <div className="popup_inner">

                <div>
                    {(props.isActive && objToPlay.type === "video") &&
                    <ReactPlayer width="100%"
                                 height="85vh"
                                 controls={true}
                                 playing={true}
                                 onEnded={updateIndex}
                                 url={objToPlay.url} />
                    }
                    {(props.isActive && objToPlay.type === "text") &&
                        <div>
                            <div className="text-block d-flex-center bg-xmas">
                                <h2 style={{width: '100%'}}>{"«" + objToPlay.text + "»"}</h2>
                                {isSound &&
                                    <audio id="audioplayer" loop autoPlay src={AUDIO_URL} onPlay={() => reduceAudioVolume()}/>
                                }
                            </div>
                        </div>
                    }
                </div>

                <div className="close_container">

                    <div className="d-flex-wrap">
                        {(props.isActive && objToPlay.type === "text") &&
                            <button className="mr-10 w-250 mb-10" onClick={() => navigateToDreams()}>На отдельной странице</button>
                        }

                        {(props.isActive && objToPlay.type === "text") &&
                        <span>
                            <img src={left}  style={{marginRight: 5}} alt="back ico" onClick={() => updateIndexBack()}/>
                            <img src={right} style={{marginRight: 5}} alt="next ico" onClick={updateIndex} />
                            <img src={pause} style={{opacity: (!paused) ? 1 : .3, marginRight: 5}} alt="pause ico" onClick={() => setPause(!paused)}/>
                            <img src={soundOff} style={{opacity: (isSound) ? 1 : .3, marginRight: 5}} alt="close ico" onClick={() => triggerSound()}/>
                        </span>
                        }
                    </div>

                    <img src={close} alt="close ico" onClick={closePopup}/>
                </div>

            </div>

        </div>
    )
}

const mapStateToProps = ({ data: { texts, visibleVideos, vloading, tloading, limit, bounds, selected }}) => {
    return { videos: visibleVideos, texts: texts, vloading, tloading, limit, bounds, selected };
};

export default connect(mapStateToProps)(PopupPlayer);
