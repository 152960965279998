import React, {Component} from 'react';
import { connect } from 'react-redux';
import {fetchSelected, fetchVideos, fetchCounters, fetchTexts, fetchTextsPreview} from '../../actions';
import BackgroundVideo from "../background-video";
import {Switch, Route, withRouter} from "react-router-dom"

import "./main.css";
import "./shortcuts.css";
import Header from "../header";
import AboutProject from "../about-project";
import HowtoBot from "../howto-bot";
import Map from "../map";
//import SelectedDreams from "../selected-dreams";
import Footer from "../footer";
import FooterBottom from "../footer-bottom";
import PopupPlayer from "../popup-player";
import DreamsPage from "../dreams";
import SelectedDreams from "../selected-dreams/selected-dreams";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isPopup:false,
      contentType: "video",
      index: 0
    }
  }

  componentDidMount() {
    this.props.fetchCounters();
    this.props.fetchSelected();
    this.props.fetchVideos();
    this.props.fetchTexts();
    this.props.fetchTextsPreview();
  }

  triggerPopup = (e, flag, type, index=0) => {
    if (e) e.preventDefault()

      this.setState({
        isPopup: flag,
        contentType: type,
        index: index
      })
  }

  render () {

    const { loading, error } = this.props;

    if (loading) return (
        <h5>loading...</h5>
    )
    if (error) return null

    return (
        <div className="wrapper">
          <Switch>
            <Route path='/' exact>
              <BackgroundVideo />
              <div className="container">
                <div className="container-fixed-width">
                  <Header />
                  <AboutProject />
                  <HowtoBot />
                  <Map callback={(type) => this.triggerPopup(null, true, type)} />
                  <SelectedDreams callback={(index) => this.triggerPopup(null, true, "selected", index)} />
                  <Footer />
                  <FooterBottom />
                </div>
                {this.state.isPopup &&
                  <PopupPlayer history={this.props.history} index={this.state.index} isActive={this.state.isPopup} type={this.state.contentType} callback={this.triggerPopup} />
                }
              </div>
            </Route>
            <Route path='/dreams'>
                <DreamsPage />
            </Route>
          </Switch>
        </div>
    );
  }
}

const mapStateToProps = ({ data: { data, loading, error, limit }}) => {
  return { data, loading, error, limit };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSelected: fetchSelected(dispatch),
    fetchVideos: fetchVideos(dispatch),
    fetchCounters: fetchCounters(dispatch),
    fetchTexts: fetchTexts(dispatch),
    fetchTextsPreview: fetchTextsPreview(dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
