
const FooterBottom = props => {

    return (
        <div className='bottomfooter-container'>

            <p><a href="https://golos2020.org/" target="_blank" rel="noreferrer">Платформа «Голос»</a></p>
            <p className="mt-20"><a href="https://tttttt.me/golosby_feedback_bot">Связаться с разработчиками</a></p>

        </div>
    )
}

export default FooterBottom;
