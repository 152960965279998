import React, { useEffect, useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import FooterBottom from "../footer-bottom";
import FormMap from "../map/form-map";
import { fetchDreams, resetDreams } from "../../actions";
import logo from "../../assets/logo_vs.svg";

const DREAMS_PAGE_SIZE = 300;

const DreamsPage = (props) => {

  const [isMapCollapsed, setIsMapCollapsed] = useState(true);

  const toggleMap = useCallback(() => {
    setIsMapCollapsed(!isMapCollapsed)
  }, [setIsMapCollapsed, isMapCollapsed]);

  const boundsParams = useRef(props.bounds);

  useEffect(() => {
    const params = {
      bounds: props.bounds,
      limit: DREAMS_PAGE_SIZE,
    };
    props.fetchDreams(params);

    return () => {
      props.resetDreams();
    }
  }, []);

  const updateDreams = useCallback(({ bounds }) => {
    props.resetDreams();

    const sw = `${bounds.Wa.i},${bounds.Ra.i}`;
    const ne = `${bounds.Wa.j},${bounds.Ra.j}`;

    const params = {
      bounds: { sw, ne },
      limit: DREAMS_PAGE_SIZE,
    };
    props.fetchDreams(params);
    boundsParams.current = params.bounds;
  }, [props.fetchDreams, props.resetDreams]);

  const scrollIndicator = useRef();

  const loadMore = useCallback(([entry]) => {
    if (props.hasMoreDreams && !props.loading && entry.isIntersecting) {

      const params = {
        bounds: boundsParams.current,
        limit: DREAMS_PAGE_SIZE,
        offset: props.dreams.length,
      };
      props.fetchDreams(params);
    }
  }, [props.hasMoreDreams, props.loading, props.dreams, boundsParams]);

  useEffect(() => {
    if (scrollIndicator.current) {
      const observer = new IntersectionObserver(loadMore, { rootMargin: '0px 0px 500px 0px' });
      observer.observe(scrollIndicator.current);

      return () => {
        if (scrollIndicator.current) {
          observer.unobserve(scrollIndicator.current);
        }
      };
    }
  }, [loadMore, scrollIndicator]);

  return (
    <div className="dreams-container">
      <div className="">

        <div className='header-wrapper'>
          <div className="dreams-header-container">
            <div>
              <Link to="/">
                <img width={150} src={logo} alt="logo" />
              </Link>

              <div className="d-flex-wrap-sb">
                <div className="mt-20 mb-20">
                  {<Link style={{color: "white"}} to="/">ГЛАВНАЯ СТРАНИЦА</Link>}
                    {<span> / МЕЧТЫ</span>}
                </div>
                {
                  isMapCollapsed && (
                      <div>
                        <a className="mt-20 mr-10" onClick={toggleMap}>
                          <button>
                            Мечты на карте
                          </button>
                        </a>
                      </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <div
          className="dreams-map"
          style={{ display: isMapCollapsed ? 'none' : 'block'}}
        >
          <a className="p-10 collapse-map" onClick={toggleMap}>
            <button>
              Свернуть карту
            </button>
          </a>
          <FormMap
            texts={props.dreams.slice(0, DREAMS_PAGE_SIZE)}
            videos={[]}
            initialBounds={props.bounds}
            initialZoom={props.zoom}
            initialCenter={props.center}
            callback={updateDreams}
          />
        </div>

        <div className="d-flex-center">
          <div className="dreams-content">
            {
              props.dreams.map((item) => {
                return <div className="pr-15 pl-15" key={item.id}>
                  <p className="mt-30 mb-30">{item.text}</p>
                  <hr />
                </div>
              })
            }
            {props.loading && (<p className="mt-30 mb-30 wp-100">Загрузка данных...</p>)}
          </div>
          <div ref={scrollIndicator} />
        </div>
        <FooterBottom />
      </div>
    </div>
  );
};


const mapStateToProps = ({ data: { bounds, zoom, center, dreams, dloading, hasMoreDreams }}) => {
  return { bounds, zoom, dreams, center, loading: dloading, hasMoreDreams };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDreams: fetchDreams(dispatch),
    resetDreams: () => dispatch(resetDreams()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DreamsPage);
