import whitestar from "../../assets/whitestar.svg"

const AboutProject = props => {

    return (
        <div id="about" className='about-container'>
            <h2 className="mb-30">О чём «Беларусь мечтает»</h2>
            <p className="mb-30 wp-95">
                Платформа «Голос» запускает проект о Новой Беларуси — счастливой, справедливой и безопасной. Поделитесь до 7 января включительно своей мечтой с нами, чтобы вместе превратить её в план действий.
            </p>

            <div className="d-flex-wrap-sb">
                <div className="column-item">
                    <div className="d-flex mb-20">
                        <div className="star" >
                            <img width={16} height={16} src={whitestar} alt="white star" />
                        </div>
                        <p>Всем хочется верить, что в новом году всё будет не по новой, а по-новому. Поэтому «Голос» создал открытую площадку, где можно вместе помечтать о том, какой мы хотим видеть родную страну.</p>
                    </div>
                    <div className="d-flex mb-20">
                        <div className="star" >
                            <img width={16} height={16} src={whitestar} alt="white star" />
                        </div>
                        <p>Настоящий и единственный двигатель перемен в Беларуси — это белорусское общество. На протяжении полугода все важнейшие для страны решения принимал народ Беларуси. Так будет и впредь.</p>
                    </div>

                </div>

                <div className="column-item">
                    <div className="d-flex mb-20">
                        <div className="star" >
                            <img width={16} height={16} src={whitestar} alt="white star" />
                        </div>
                        <p>Проект «Беларусь мечтает» — это наш с вами способ продемонстрировать единство и загадать желания о Новой Беларуси, которые исполнятся в новом году.</p>
                    </div>
                    <div className="d-flex mb-20">
                        <div className="star" >
                            <img width={16} height={16} src={whitestar} alt="white star" />
                        </div>
                        <p>Глобальные мечты или локальные проблемы — проект «Беларусь мечтает» собирает все идеи, которые могут сделать жизнь белорусов лучше прямо сейчас и в ближайшем будущем.</p>
                    </div>

                </div>

                <div className="column-item">
                    <div className="d-flex mb-20">
                        <div className="star" >
                            <img width={16} height={16} src={whitestar} alt="white star" />
                        </div>
                        <p>Вы хотите жить в стране, где правительство служит белорусским гражданам, а выходить на улицу безопасно для всех? Или вам хотелось бы, чтобы молодежь оставалась в стране, чтобы малый бизнес получал поддержку? А возможно, вам не хватает пандуса у себя в подъезде или зелени рядом с домом?</p>
                    </div>
                    <div className="d-flex mb-20">
                        <div className="star" >
                            <img width={16} height={16} src={whitestar} alt="white star" />
                        </div>
                        <p>Запишите свои мечты в видеоформате или текстом и отправьте чат-боту «Голоса» до 7 января 2021 года включительно. «Голос» не опубликует имена и фамилии авторов сообщений.</p>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default AboutProject;
