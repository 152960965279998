import React, { Component, useRef, useEffect, useCallback } from "react";
import {withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer"
import voicestar from "../../assets/voicestar.svg"

const centerAddress = { address: 'Minsk, Belarus', lat: 53.9006011, lng: 27.558972 }

const FormGoogleMap = withGoogleMap((props) => {
  const mapRef = useRef();
  const updateRef = useRef(null);

  const update = useCallback(() => {
    clearTimeout(updateRef.current);
    updateRef.current = setTimeout(() => {
      const params = {
        bounds: mapRef.current.getBounds(),
        zoom: mapRef.current.getZoom(),
        center: {
          lat: mapRef.current.getCenter().lat(),
          lng: mapRef.current.getCenter().lng(),
        }
      };
      props.callbackUpdate(params);
    }, 300);
  }, [mapRef, props.callbackUpdate]);

  useEffect(() => () => clearTimeout(updateRef.current), []);

  useEffect(() => {
    if (props.initialBounds && props.initialBounds.sw && props.initialBounds.ne) {
      const [south, west] = props.initialBounds.sw.split(',');
      const [north, east] = props.initialBounds.ne.split(',');

      mapRef.current.panToBounds({
        south: Number(south),
        west: Number(west),
        north: Number(north),
        east: Number(east),
      });
    }
  }, []);

  return (
    <GoogleMap
      ref={mapRef}
      onDragEnd={update}
      onZoomChanged={update}
      defaultZoom={props.initialZoom}
      defaultCenter={props.initialCenter}
      defaultOptions={{
        maxZoom: 10,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false}}
    >
      {props.children}
    </GoogleMap>
  );
});


export default class FormMap extends Component {

    boundsUpdate = (params) => {
      this.props.callback(params);
    }

  render() {
    const videoMarkets = (this.props.videos) ? this.props.videos.map((item) =>
            <Marker icon={voicestar} clickable={false} key={item.id} position={{ lat: Number(item.lat), lng: Number(item.lon) }} />) : [];

    const textMarkets = (this.props.texts) ? this.props.texts.map((item) =>
          <Marker icon={voicestar} clickable={false} key={item.id} position={{ lat: Number(item.lat), lng: Number(item.lon) }} />) : [];

    const markers = [...videoMarkets, ...textMarkets]

    return (
      <FormGoogleMap
        initialCenter={this.props.initialCenter || centerAddress}
        callbackUpdate={this.boundsUpdate}
        initialZoom={this.props.initialZoom}
        initialBounds={this.props.initialBounds}
        loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
        containerElement={<div style={{ height: `600px`, width: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      >
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            calculator={(markers) => {
                if (markers.length >= 50) return {text: "", index: 3}; // red
                if (markers.length >= 5) return {text: "", index: 2};  // yellow
                return {text: "", index: 0}; //blue
                }
            }
            defaultMaxZoom={10}
          >
            {[markers]}
          </MarkerClusterer>
      </FormGoogleMap>
    )
  }
}