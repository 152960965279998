import { DEFAULT_ZOOM } from '../constants';

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

const updateData = (state, action) => {

    if (state === undefined) {
        return {
            // videos
            videos: [],
            visibleVideos: [],
            vloading: false,

            // text preview
            textsPreview: [],
            visibleTextPreview: [],

            // main page map
            bounds: null,
            zoom: DEFAULT_ZOOM,
            center: null,

            // dreams
            dreams: [],
            hasMoreDreams: true,
            dloading: false,

            // counters
            counters: {},

            // errors
            error: null,

            // NOT USED
            selected: [],
            loading: false,
            texts: [],
            tloading: false,
        };
    }

    switch (action.type) {
        case 'FETCH_DATA_REQUEST':
            return {
                ...state.data,
                loading: true,
            };

        case 'FETCH_DATA_SUCCESS':
            return {
                ...state.data,
                selected: action.payload,
                loading: false,
            };

        case 'FETCH_DATA_FAILURE':
            return {
                selected: [],
                loading: false,
                error: action.payload
            };

        case 'FETCH_VIDEOS_REQUEST':
            return {
                ...state.data,
                vloading: true,
            };

        case 'FETCH_TEXTS_REQUEST':
            return {
                ...state.data,
                tloading: true,
            };

        case 'FETCH_DREAMS_REQUEST':
            return {
                ...state.data,
                dloading: true,
            };

        case 'RESET_DREAMS':
            return {
                ...state.data,
                dreams: [],
                dloading: false,
                hasMoreDreams: true,
            };

        case 'FETCH_VIDEOS_SUCCESS':
            const videos = shuffleArray(action.payload.content);
            return {
                ...state.data,
                videos,
                vloading: false,
                visibleVideos: videos,
            };

        case 'FETCH_TEXTS_SUCCESS':
            return {
                ...state.data,
                texts: shuffleArray(action.payload.content),
                tloading: false,
            };

        case 'FETCH_DREAMS_SUCCESS':
            return {
                ...state.data,
                dreams: [
                    ...state.data.dreams,
                    ...action.payload.content,
                ],
                dloading: false,
                hasMoreDreams: action.payload.content.length === action.payload.limit,
            };

        case 'FETCH_COUNTERS_SUCCESS':
            return {
                ...state.data,
               counters: action.payload
            };

        case 'FETCH_TEXTSPREVIEW_SUCCESS':
            const textsPreview = shuffleArray(action.payload);
            return {
                ...state.data,
                textsPreview,
                visibleTextPreview: textsPreview,
            };

        case 'UPDATE_MAP_VISIBLE_AREA': {
            const { bounds, zoom, center } = action.payload;

            const visibleTextPreview = state.data.textsPreview.filter(({ lat, lon }) => {
                return bounds.contains({ lat: Number(lat), lng: Number(lon) });
            });

            const visibleVideos = state.data.videos.filter(({ lat, lon }) => {
                return bounds.contains({ lat: Number(lat), lng: Number(lon) });
            });

            const sw = `${bounds.Wa.i},${bounds.Ra.i}`;
            const ne = `${bounds.Wa.j},${bounds.Ra.j}`;

            return {
                ...state.data,
                visibleTextPreview: shuffleArray(visibleTextPreview),
                visibleVideos: shuffleArray(visibleVideos),
                bounds: { sw, ne },
                zoom,
                center,
            }
        }

        default:
            return state.data;
    }
};

export default updateData;
