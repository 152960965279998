import { LIMIT, DEFAULT_ZOOM } from '../constants';
import APIService from "../services/api-service";

const selectedRequested = () => {
    return {
        type: 'FETCH_DATA_REQUEST'
    }
};

const selectedLoaded = (data) => {
    return {
        type: 'FETCH_DATA_SUCCESS',
        payload: data
    };
};

const selectedError = (error) => {
    return {
        type: 'FETCH_DATA_FAILURE',
        payload: error
    };
};

const videosRequested = () => {
    return {
        type: 'FETCH_VIDEOS_REQUEST'
    }
};

const videosLoaded = (data) => {
    return {
        type: 'FETCH_VIDEOS_SUCCESS',
        payload: data
    };
};

const textsRequested = () => {
    return {
        type: 'FETCH_TEXTS_REQUEST'
    }
};

const textsLoaded = (data) => {
    return {
        type: 'FETCH_TEXTS_SUCCESS',
        payload: data
    };
};

const dreamsRequested = () => {
    return {
        type: 'FETCH_DREAMS_REQUEST'
    }
};

const dreamsLoaded = (data) => {
    return {
        type: 'FETCH_DREAMS_SUCCESS',
        payload: data
    };
};

const resetDreams = (data) => {
    return {
        type: 'RESET_DREAMS',
        payload: data
    };
};

const textsPreviewRequested = () => {
    return {
        type: 'FETCH_TEXTSPREVIEW_REQUEST'
    }
};

const textsPreviewLoaded = (data) => {
    return {
        type: 'FETCH_TEXTSPREVIEW_SUCCESS',
        payload: data
    };
};

const countersLoaded = (data) => {
    return {
        type: 'FETCH_COUNTERS_SUCCESS',
        payload: data
    };
};

const updateMapVisibleArea = (data) => {
    return {
        type: 'UPDATE_MAP_VISIBLE_AREA',
        payload: data
    };
};

const fetchSelected = (dispatch) => (...args) => {
    const apiService = new APIService();
    dispatch(selectedRequested());
    apiService.getSelected()
        .then((data) => dispatch(selectedLoaded(data)))
        .catch((err) => dispatch(selectedError(err)));
};

const fetchVideos = (dispatch) => ({bounds= null, zoom= DEFAULT_ZOOM, offset= 0, limit= LIMIT, type= "video"} = {}) => {
    const apiService = new APIService();
    dispatch(videosRequested());
    apiService.getVideosByParam(bounds, offset, limit, "video")
        .then((data) => dispatch(videosLoaded({content: data, bounds, offset, zoom})))
        .catch((err) => console.log(err));
};

const fetchTexts = (dispatch) => ({ bounds= null, zoom = DEFAULT_ZOOM, offset= 0, limit= LIMIT} = {}) => {
    const apiService = new APIService();
    dispatch(textsRequested());
    apiService.getVideosByParam(bounds, offset, limit, "text")
        .then((data) => dispatch(textsLoaded({content: data, bounds, offset, zoom})))
        .catch((err) => console.log(err));
};

const fetchDreams = (dispatch) => ({ bounds= null, zoom = DEFAULT_ZOOM, offset= 0, limit= LIMIT} = {}) => {
    const apiService = new APIService();
    dispatch(dreamsRequested());
    apiService.getVideosByParam(bounds, offset, limit, "text")
      .then((data) => dispatch(dreamsLoaded({content: data, bounds, offset, zoom, limit})))
      .catch((err) => console.log(err));
};

const fetchTextsPreview = (dispatch) => (limit=5) => {
    const apiService = new APIService();
    dispatch(textsPreviewRequested());
    apiService.getTextsPreview(limit, "text")
        .then((data) => dispatch(textsPreviewLoaded(data)))
        .catch((err) => console.log(err));
};

const fetchCounters = (dispatch) => () => {
    const apiService = new APIService();
    apiService.getCounters()
        .then((data) => dispatch(countersLoaded(data)))
        .catch((err) => console.log(err));
};


export {
    fetchSelected, fetchVideos, fetchCounters, fetchTexts, fetchTextsPreview, updateMapVisibleArea, fetchDreams, resetDreams,
};
