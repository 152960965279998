
const Footer = props => {

    return (
        <div className='footer-container'>
            <div className="shadowed-container">
                <div className="shadowed-text">
                    <h2 className="mb-30">О платформе «Голос»</h2>
                    <p className="mb-20">
                        Платформа «Голос» создавалась белорусскими программистами для выявления фальсификаций на президентских выборах, с чем успешно справилась.
                    </p>
                    <p className="mb-20">
                        Затем функционал «Голоса» расширился: теперь на платформе мы проводим голосования по актуальным вопросам, изучаем мнение белорусов о решениях, которые собираются принять демократические силы или действующая власть, ведём подсчёт протестующих, создаём народные прообразы Конституции и других законов, информируем о самых важных событиях.
                    </p>
                    <p className="mb-20">
                        Национальное волеизъявление — в ДНК «Голоса», поэтому мечты и идеи, которыми белорусы поделятся с «Голосом», не останутся просто мечтами и идеями.
                    </p>
                    <p className="mb-20">
                        Конкретные проблемы лягут в основу будущих действий самой платформы и других гражданских инициатив в 2021 году. Из глобальных мечтаний белорусских граждан, после обработки и систематизации, будет создан Манифест будущей Беларуси.
                    </p>
                    <p className="mb-20">
                        Быть в «Голосе» — значит участвовать в создании Новой Беларуси. И влиять на самые важные события нашей страны.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Footer;
