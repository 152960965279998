import logotg from "../../assets/logo_tg.svg";
import logovb from "../../assets/logo_vb.svg";
import location from "../../assets/ico_location.svg"
import phone from "../../assets/ico_phone.svg"
import timer from "../../assets/ico_timer.svg"

const HowtoBot = props => {

    return (
        <div className='bot-container'>

            <h2 className="mb-30 mr-30">Как отправить <br/>мечту «Голосу»</h2>

            <div className="bot-wrapper">
                <p className="bot-text">Запишите вертикальное видео длиной до 15 секунд или текст и сохраните</p>
                <div className="ico-container">
                    <div className="d-flex mr-10">
                        <img width={40} height={40} src={phone} alt="location" />
                    </div>
                    <div className="d-flex">
                        <img width={40} height={40} src={timer} alt="location" />
                        <p>10-15с</p>
                    </div>


                </div>
            </div>

            <div className="bot-wrapper">
                <p className="bot-text">Установите чат-бота «Голоса» и зарегистрируйтесь</p>
                <div className="ico-container">
                    <img width={40} height={40} src={logotg} alt="logo tg" />
                    <img width={40} height={40} className="ml-10" src={logovb} alt="logo tg" />
                </div>
            </div>

            <div className="bot-wrapper">
                <p className="bot-text">Просто отправьте чат-боту «Голоса» видео или текст или введите в чате команду /ямечтаю</p>
                <div className="ico-container">
                    <h3>/ямечтаю</h3>
                </div>
            </div>

            <div className="bot-wrapper">
                <p className="bot-text">Укажите свое местоположение, или введите его вручную, чтобы мы отметили ваш город на карте</p>
                <div className="ico-container">
                    <img width={40} height={40} src={location} alt="location" />
                </div>
            </div>


        </div>
    )
}

export default HowtoBot;
